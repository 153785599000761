const languages = {
    "de": [
        { "error": "Ein Fehler ist aufgetreten!" },
        { "server-id": "inettBackupServer" },
        { "passwords_dont_match": "Passwörter stimmen nicht überein." },
        { "account_not_active": "Bitte warten Sie bis ihr Account innerhalb 2 Werktagen freigeschaltet wird. Sie erhalten eine Benachrichtigung per E-Mail." },
        { "welcome": "Willkommen" },
        { "login_page_text_1": "Sichern Sie Ihre Daten mit Leichtigkeit." },
        { "login_page_text_2": "Über unseren Self-Service können Sie Ihre Backups eigenständig verwalten – schnell, flexibel und zuverlässig." },
        { "sidebar_dashboard": "Dashboard" },
        { "sidebar_tutorial": "Anleitung" },
        { "headline_setup_storage": "Storage" },
        { "headline_setup_backup_job": "Backup Job" },
        { "go_to_datastore": "Zum Datastore" },
        { "email": "E-Mail" },
        { "password": "Passwort" },
        { "buy": "Kaufen" },
        { "password_policy": "Das Passwort muss länger als 15 Zeichen sein, Klein- und Großbuchstaben sowie mindestens eine Zahl enthalten." },
        { "forgot_pw_text_1": "Das kann passieren!" },
        { "forgot_pw_text_2": "Geben Sie einfach Ihre E-Mail-Adresse ein, und wir schicken Ihnen einen Link zum Zurücksetzen Ihres Passworts." },
        { "change_password_text": "Ändern Sie hier Ihr Passwort." },
        { "change_password": "Passwort ändern!" },
        { "headline_waiting_account": "Ihr Account wird bald freigeschaltet" },
        { "text_waiting_account": "Bald geht es los! Innerhalb von drei Werktagen wird Ihr Account freigeschaltet." },
        { "text_waiting_account_email_info": `Wir prüfen aktuell Ihre Daten und werden Ihren Account in Kürze freischalten. 
            Danach können Sie sämtliche Funktionen nutzen, um Ihre Einstellungen zu verwalten oder Services anzupassen. 
            Wir informieren Sie sobald es losgehen kann per E-Mail.` },
        { "text_waiting_account_email_info_end": "Vielen Dank für Ihre Geduld." },
        { "headline_notifications_disabled": "Benachrichtigungen abgeschaltet" },
        { "text_activate_notifications_1": `Im Menüpunkt ` },
        { "text_activate_notifications_2": ` haben Sie die Möglichkeit, Ihre Benachrichtigungen erneut zu aktivieren.` },
        { "text_notifications_disabled": `Sie haben Ihre Benachrichtigungen abgeschaltet.
            Wenn Sie wieder Benachrichtigungen erhalten möchten, überprüfen Sie bitte Ihre Einstellungen 
            und aktivieren Sie sie erneut.` },
        { "password_request_send": "Sie erhalten eine E-Mail mit der Sie ein neues Passwort festlegen können." },
        { "password_changed": "Ihr Passwort wurde geändert." },
        { "registration": "Registrierung" },
        { "registration_text": "Erstellen Sie jetzt Ihr Konto, um vollen Zugriff auf unser Self-Service Portal zu erhalten und Ihre Services ganz einfach selbst zu verwalten." },
        { "sidebar_company": "Account" },
        { "sidebar_settings": "Einstellungen" },
        { "forgot_pw_button": "Passwort vergessen" },
        { "change_password_button": "Neues Passwort" },
        { "register_account": "Account anlegen" },
        { "sidebar_logout": "Abmelden" },
        { "contact": "Kontakt" },
        { "message": "Nachricht" },
        { "notification": "Benachrichtigung" },
        { "notification_text": "Möchten Sie weiterhin Informationen über unsere Dienste erhalten?" },
        { "notification_checkbox": "Mitteilungen per E-Mail" },
        { "button_send": "Absenden" },
        { "contact_message_send": "Ihre Nachricht wurde versendet." },
        { "contact_infotext": `Falls Sie Fragen haben oder Unterstützung benötigen, zögern Sie bitte nicht, uns zu kontaktieren.
            Unser Support-Team steht Ihnen gerne zur Verfügung und hilft Ihnen bei allen Anliegen weiter.
            Ob technische Fragen, Anleitungen oder allgemeine Informationen – wir sind hier, um Ihnen zu helfen!
            Schreiben Sie uns einfach, und wir kümmern uns schnellstmöglich um Ihr Anliegen.`},
        { "best_regards" : "Freundliche Grüße, Ihr Support-Team" },
        { "statuspage": "Service Status" },
        { "register": "Anmelden" },
        { "login": "Einloggen" },
        { "send": "Senden" },
        { "name": "Name" },
        { "company_data": "Firmendaten" },
        { "company_name": "Firmenname (optional)" },
        { "company_number": "Kundennummer" },
        { "firstname": "Vorname" },
        { "lastname": "Nachname" },
        { "street": "Straße" },
        { "postcode": "Postleitzahl" },
        { "city": "Ort" },
        { "state": "Bundesland" },
        { "country": "Land" },
        { "phone": "Telefonnummer" },
        { "datastore": "Datastore" },
        { "hostname": "Hostname" },
        { "username": "Username" },
        { "port": "Port" },
        { "password": "Password" },
        { "support": "E-Mail Support" },
        { "cancellation": "Kündigung" },
        { "cancel_subject_mail": "Kündigung unseres Vertrags" },
        { "cancel_text": `Wir möchten Sie darüber informieren, dass Sie jederzeit 
            die Möglichkeit haben Ihren Vertrag zu kündigen. Die Kündigung kann 
            schriftlich oder per E-Mail erfolgen.` },
        { "space": "Speicherplatz" },
        { "used_space": "Genutzter Speicherplatz" },
        { "costs_gigabyte": "Kosten pro Gigabyte" },
        { "ustid": "Umsatzsteuer-Identifikationsnummer" },
        { "optional": "optional" },
        { "contracts": "Verträge" },
        { "pbs_login": "Im PBS einloggen" },
        { "pbs_config": "Konfiguration des Backuptransfers" },
        { "info_login_pbs": `Mit den bereitgestellten Logindaten können Sie sich im Proxmox Backup Server 
            einloggen. Diese Anmeldedaten ermöglichen Ihnen den Zugriff auf die Benutzeroberfläche 
            des Backup Servers, sowie das Einrichten der Backupstransfers.` },
        { "info_go_to_datastore": "Ihr Datastore bietet Ihnen eine zentrale Anlaufstelle zur Verwaltung Ihrer Backups." },
        { "info_backup_config": `Gehen Sie in Ihre Proxmox VE-Weboberfläche und navigieren Sie zu den 
            Backup-Einstellungen Ihrer virtuellen Maschinen oder Container. Dort können Sie einen
            neuen Backup-Job erstellen, indem Sie den gewünschten Datastore auswählen und die zu 
            sichernden VMs oder Container angeben.` },
        { "text_static_product": `Festpreis-Abrechnung: Es wird die gebuchte Speichergröße 
            zum monatlichen Abrechnungstag in Rechnung gestellt. Der Gesamtpreis 
            ergibt sich aus dem Einzelpreis und der gebuchten Speichergröße.` },
        { "text_dynamic_product": `Verbrauchsabhängige Abrechnung: Der belegte Speicher 
            wird am monatlichen Abrechnungstag ausgewertet und in Rechnung gestellt. 
            Der Gesamtpreis ergibt sich aus dem Einzelpreis pro GB und dem belegten 
            Speicher.` },
        { "storage-text-0": `
            Die Sicherung und Wiederherstellung von virtuellen Maschinen und Containern ist eine 
            kritische Komponente jeder IT-Infrastruktur. Der Proxmox Backup Server bietet eine
            leistungsstarke und flexible Lösung für die Sicherung von Proxmox VE-Umgebungen.
            Um die Effizienz und Zuverlässigkeit Ihrer Backups zu maximieren, ist eine
            sorgfältige Einrichtung des Speichers auf dem Backup-Server unerlässlich.
        ` },
        { "storage-text-1": `
            In dieser Anleitung werden wir die Schritte zur Konfiguration von Storage
            für den Proxmox Backup Server detailliert durchgehen. Dabei betrachten wir
            verschiedene Speichermöglichkeiten, die sowohl Leistung als auch Sicherheit gewährleisten.
        ` },
        { "storage-text-1": `
            Klicken Sie in Ihrer Proxmox Virtual Environment auf Ihr "Rechenzentrum" und anschließend auf Storage.
            Hier können Sie im Dropdown-Menü "Hinzufügen" Ihren Proxmox Backup Server hinzufügen. Bitte achten 
            Sie darauf, dass Ihr Backup verschlüsselt gespeichert wird. (Siehe Screenshot 3)
        ` },
        { "storage-text-2": `
            Im Abschnitt "Allgemein" geben Sie bitte Ihre Zugangsdaten für den Proxmox Backup Server ein.
            Diese Informationen sind im Dashboard verfügbar.
        ` },
        { "storage-text-3": `
            Bitte nehmen Sie keine Änderungen an den Einstellungen für "Aufbewahrte Backups" vor. Unser Server verwendet die empfohlenen Standardwerte.
        ` },
        { "storage-text-4": `
            Stellen Sie sicher, dass Ihre Backups stets verschlüsselt sind und nur verschlüsselte Backups 
            an uns übertragen werden.
        ` },
        { "dashboard-link": "Zum Dashboard" },
        { "backupjob-text-1": `
            Sobald Ihr Proxmox Backup Server als Speicherort konfiguriert ist, haben Sie die Möglichkeit, 
            einen Backup-Job einzurichten, der regelmäßig Sicherungen auf dem Remote-Speicher ablegt.
        ` },
        { "backupjob-text-2": `
            Bitte wählen Sie unseren Proxmox Backup Service als "Storage" aus und legen Sie einen geeigneten Zeitplan fest.
        ` },
        { "backupjob-text-3": `
            Bitte nehmen Sie keine Änderungen an den Aufbewahrungseinstellungen vor. Unser Server verwendet die empfohlenen Standardwerte.
        ` },
        { "backupjob-text-4": " " },
        { "backupjob-text-5": `
            Bitte wählen Sie das gewünschte Bandbreitenlimit für die Übertragung Ihres Backups aus.
        ` },
        { "backupjob-info-encrypted": `` },
        { "text_av_contract": "Auf Anfrage senden wir Ihnen gerne einen Auftragsverarbeitungsvertrag per E-Mail zu." },
        { "error_username_or_password": "Nutzername und Passwort stimmen nicht überein oder der Account wurde noch nicht freigeschaltet." },
        { "proxmox_backup_service": "Proxmox Backup Server in der Cloud" },
        { "text_proxmox_backup":
            `Proxmox bietet eine leistungsstarke Open-Source-Plattform zur Verwaltung von Virtualisierungen. 
            Mit unserem Self Service Proxmox Backup Server (SPBS) sichern und stellen Sie Ihre virtuellen Maschinen (VMs) 
            und Container zuverlässig wieder her – einfach und sorgenfrei.`
        },
        { "dynamic_price_text": 'Flexible Berechnung des genutzten Speicherplatzes: 0,02€ pro GB' },
        { "dynamic_space_text": 'Kosten fallen nur für den tatsächlichen Speicherverbrauch an, mit einem optimalen Preisvorteil' },
        { "static_price_gigabyte": 'Ab 1 Gigabyte für 0,02 Euro / Monat' },
        { "volume_price_text": 'Stabile Kosten für reservierten Speicherplatz: 20 € pro TB' },
        { "fixed_price_text": 'Sie zahlen den im Voraus gebuchten Speicherplatz und profitieren von einem festen Monatspreis' },
        { "headline_product_list": "Unsere Tarife im Überblick:" },
        { "short_claim": "Sicher, flexibel, kosteneffizient." },
        { "icon_claim_location": "Serverstandort Deutschland" },
        { "icon_claim_security": "Verschlüsselt mit AES 256" },
        { "icon_claim_proxmox": "Proxmox Backup Server Zugang" },
        { "text_legal_product_infos_1": "Vertragsbeginn direkt nach der Bereitstellung" },
        { "text_legal_product_infos_2": "Verrechnung einmal im Monat" },
        { "text_legal_product_infos_3": "Monatlich kündbar" },
        { "text_legal_product_infos_4": "DSGVO-konform, Auftragsdatenverarbeitungserklärung" },
        { "text_legal_product_infos_5": `Auf Anfrage per E-Mail können Sie mehr Speicherplatz erhalten.` },
        { "warning_encryption": `Bitte bewahren Sie die Verschlüsselungsschlüssel sorgfältig auf.
            Ohne diese Schlüssel können wir verschlüsselte Backups nicht wiederherstellen, 
            was dazu führt, dass die Backups unbrauchbar werden.` },
        { "info_screenshot": "Screenshot der Konfiguration" },
        { "service_description": "Vollständige Leistungsbeschreibung" },
        { "dynamic": "dynamisch" },
        { "privacy_link": "Datenschutzerklärung" },
        { "customer_information_vat": "Alle Preise verstehen sich zuzüglich der gesetzlichen Umsatzsteuer." },
        { "imprint": "Impressum" },
        { "contact_sales_label": "Sie haben Fragen oder wünschen ein individuelles Angebot? Kontaktieren Sie unseren Vertrieb" },
        { "contact_sales_email": "vertrieb@inett.de" },
        { "contact_sales_subject": "Anfrage fuer ein individuelles PBS Angebot" },
    ]
}
function t(language, keyword) {
    let languageContentArray = languages[language]
    for (let item of languageContentArray) {
        if (item[keyword]) {
            return item[keyword];
        }
    }
    return keyword;
}

export { t }