<script setup>
import { useMainStore } from '@/stores/main'
const store = useMainStore()

store.getStatus()
</script>

<template>
    <div v-if="store.proxmox_username">
        <h2>{{ store.translate("pbs_login") }}</h2>
        <p>
            {{ store.translate("info_login_pbs") }}
        </p>
        {{ store.translate("username") }}: {{ store.proxmox_username }}
        <i @click="() => { store.copyToClipboard(store.proxmox_username) }"
            class="fas fa-copy click margin-left-small"></i><br />
        {{ store.translate("password") }}: {{ store.getProxmoxPasswrd() }}
        <i @click="() => { store.togglePasswordVisibility() }" class="fas fa-eye click margin-left-small"></i>
        <i @click="() => { store.copyToClipboard(store.proxmox_password) }"
            class="fas fa-copy click margin-left-small"></i><br />
        Realm: Proxmox Backup authentication server<br />

        <div class="tut-images pbs-login">
            <div class="pbs-username">
                {{ store.proxmox_username }}
            </div>
            <div class="pbs-password">
                {{ store.proxmox_password.substring(0, 10) + '...' }}
            </div>
            <div class="pbs-screenshot">
                {{ store.translate("info_screenshot") }}
            </div>
        </div>
        <p>
            {{ store.translate("info_go_to_datastore") }}
        </p>
        <a v-bind:href="'http://' + store.proxmox_hostname" target="_blank">
            {{ store.translate("go_to_datastore") }}
        </a>
        <hr>
    </div>
    <div v-if="store.proxmox_username">
        <h2>{{ store.translate("pbs_config") }}</h2>
        <p>
            {{ store.translate("info_backup_config") }}
        </p>
        {{ store.translate("datastore") }}: {{ store.datastore }}
        <i @click="() => { store.copyToClipboard(store.datastore) }"
            class="fas fa-copy click margin-left-small"></i><br />
        {{ store.translate("hostname") }}: {{ store.proxmox_hostname }}
        <i @click="() => { store.copyToClipboard(store.proxmox_hostname) }"
            class="fas fa-copy click margin-left-small"></i><br />
        {{ store.translate("port") }}: {{ store.proxmox_port }}
        <i @click="() => { store.copyToClipboard(store.port) }" class="fas fa-copy click margin-left-small"></i><br />
        Repository: {{ store.repository }}
        <i @click="() => { store.copyToClipboard(store.repository) }"
            class="fas fa-copy click margin-left-small"></i>
    </div>
    <div v-if="store.proxmox_username">
        <div class="tut-images storage-de-dashboard-1">
            <div class="tut-server-id">
                {{ store.translate("server-id") }}
            </div>
            <div v-if="store.proxmox_hostname" class="tut-hostname">
                {{ store.proxmox_hostname }}:{{ store.proxmox_port }}
            </div>
            <div v-if="store.proxmox_username" class="tut-username">
                {{ store.proxmox_username }}
            </div>
            <div v-if="store.proxmox_password" class="tut-password">
                ***********************
            </div>
            <div v-if="store.datastore" class="tut-datastore">
                {{ store.datastore }}
            </div>
            <div class="tut-screenshot">
                {{ store.translate("info_screenshot") }}
            </div>
        </div>
    </div>
    <div v-if="store.proxmox_username">
        <hr>
        {{ store.translate("costs_gigabyte") }}: {{ store.proxmox_costs }} €<br />
        <span v-if="store.volume && store.volume > 0">
            {{ store.translate("space") }}:
            <span v-if="store.dynamicVolume">
                ({{ store.translate("dynamic") }})
            </span>
            {{ store.paidVolume }} Terabyte
            <br />
        </span>
        {{ store.translate("used_space") }}: {{ store.space_used }}<br />
    </div>
</template>

<style scoped></style>
